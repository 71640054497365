import Endpoints from '../endpoints';
import axios from '../api';
import { stringifyFilters } from '../../shared/utility';

export const Creators = {
  getLiveness: async token => {
    try {
      const { data } = await axios.get(`${Endpoints.liveness}/${token}`);
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  createLiveness: async (createData) => {
    try {
      const { data } = await axios.post(Endpoints.liveness, {
        ...createData,
        url_for_token: window.location.protocol + "//" + window.location.host + '/liveness',
      });
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  updateLiveness: async (id, updateData) => {
    try {
      const { data } = await axios.patch(`${Endpoints.liveness}/${id}`, updateData);
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro. Por favor, tente novamente' };
    }
  },
  listProposalsLiveness: async (proposal_id, filters) => {
    try {
      const sFilter = stringifyFilters(filters ?? {})
      const { data } = await axios.get(`${Endpoints.proposals}/${proposal_id}/liveness${sFilter}`);
      return data?.results ?? [];
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar carregar a lista' };
    }
  },
  listCreditCardLiveness: async (credit_card_id, filters) => {
    try {
      const sFilter = stringifyFilters(filters ?? {})
      const { data } = await axios.get(`${Endpoints.credit_card}/${credit_card_id}/liveness${sFilter}`);
      return data?.results ?? [];
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar carregar a lista' };
    }
  },
};