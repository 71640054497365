import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_CERTIFICATES: 'certificates/GET_ALL',
  GET_CERTIFICATES_SUCCESS: 'certificates/GET_ALL_SUCCESS',
  GET_CERTIFICATES_ERROR: 'certificates/GET_ALL_ERROR',
  RESET: 'certificates/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
  filter: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CERTIFICATES:
      return { ...state, loading: true };
    case actionTypes.GET_CERTIFICATES_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        error: null,
        data: action.payload.data, 
        filter: action.payload.filter,
      };
    case actionTypes.GET_CERTIFICATES_ERROR:
      return { ...initialState, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

export const Creators = {
  getCertificates: filter => async dispatch => {
    dispatch({ type: actionTypes.GET_CERTIFICATES });
    try {
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.certificates}${filter_str}`);
      dispatch({
        type: actionTypes.GET_CERTIFICATES_SUCCESS,
        payload: { data, filter }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_CERTIFICATES_ERROR,
          payload: { error: 'Não foi possível buscar os atendimentos no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_CERTIFICATES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_CERTIFICATES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_CERTIFICATES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getCertificate: async certificateId => {
    try {
      const { data } = await axios.get(`${Endpoints.certificates}/${certificateId}`);
      return data;
    } catch (err) {
      return { error: 'Não foi possível atualizar as informações do cliente no momento' };
    }
  },

  getCertificateNotes: async certificateId => {
    try {
      const { data } = await axios.get(`${Endpoints.certificates}/${certificateId}/notes`);
      return data;
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  createCertificateNote: async (certificateId, analystId, text) => {
    try {
      const { data } = await axios.post(
        `${Endpoints.certificates}/${certificateId}/notes`, 
        {
          'certificate': certificateId, 
          'analyst': analystId, 
          'text': text
        }
      );
      return data;
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a observação. Por favor, tente novamente' };
    }
  },
  deleteCertificateNote: async (certificateId, noteId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.certificates}/${certificateId}/notes/${noteId}`);
      return data;
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a observação. Por favor, tente novamente' };
    }
  },
  updateCertificate: async (certificateId, fields) => {
    try {
      delete fields.id;
      const { data } = await axios.patch(`${Endpoints.certificates}/${certificateId}`, fields);
      return data;
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar o atendimento. Por favor, tente novamente' };
    }
  },
  resetCertificates: () => ({ type: actionTypes.RESET }),
};