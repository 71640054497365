import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_PRODUCTS: 'products/GET_ALL',
  GET_PRODUCTS_SUCCESS: 'products/GET_ALL_SUCCESS',
  GET_PRODUCTS_ERROR: 'products/GET_ALL_ERROR',
  GET_PRODUCT_COMPANIES: 'product_companies/GET_ALL',
  GET_PRODUCT_COMPANIES_SUCCESS: 'product_companies/GET_ALL_SUCCESS',
  GET_PRODUCT_COMPANIES_ERROR: 'product_companies/GET_ALL_ERROR',
  RESET: 'products/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS:
    case actionTypes.GET_PRODUCT_COMPANIES:
      return { ...state, loading: true };
    case actionTypes.GET_PRODUCTS_SUCCESS:
    case actionTypes.GET_PRODUCT_COMPANIES_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
      };
    case actionTypes.GET_PRODUCTS_ERROR:
    case actionTypes.GET_PRODUCT_COMPANIES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...initialState };
    default:
      return state;
  }
}

// policy

export const Creators = {
  getProducts: (filter) => async dispatch => {
    dispatch({ type: actionTypes.GET_PRODUCTS });
    try {
      filter = filter ?? {};
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.products}${filter_str}`);
      dispatch({
        type: actionTypes.GET_PRODUCTS_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PRODUCTS_ERROR,
          payload: { error: 'Não foi possível buscar o(s) convênio(s) no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_PRODUCTS_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_PRODUCTS_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PRODUCTS_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProductCompanies: () => async dispatch => {
    dispatch({ type: actionTypes.GET_PRODUCT_COMPANIES });
    try {
      const { data } = await axios.get(Endpoints.product_companies);
      dispatch({
        type: actionTypes.GET_PRODUCT_COMPANIES_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_PRODUCT_COMPANIES_ERROR,
          payload: { error: 'Não foi possível buscar o(s) convênio(s) no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_PRODUCT_COMPANIES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_PRODUCT_COMPANIES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_PRODUCT_COMPANIES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getProduct: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.products}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter o(s) convênio(s). Por favor, tente novamente' };
    }
  },
  createProduct: async (fields) => {
    try {
      const { data } = await axios.post(`${Endpoints.products}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar o(s) convênio(s). Por favor, tente novamente' };
    }
  },
  updateProduct: async (productId, fields) => {
    try {
      const { data } = await axios.put(`${Endpoints.products}/${productId}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar o(s) convênio(s). Por favor, tente novamente' };
    }
  },
  deleteProduct: async (productId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.products}/${productId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar o(s) convênio(s). Por favor, tente novamente' };
    }
  },
  restoreProduct: async (productId) => {
    try {
      const { data } = await axios.post(`${Endpoints.products}/${productId}/restore`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar restaurar o convênio. Por favor, tente novamente' };
    }
  },
  resetProduct: () => ({ type: actionTypes.RESET })
};
