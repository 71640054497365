import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_INFO: 'InsuranceInfo/GET_ALL',
  GET_INFO_SUCCESS: 'InsuranceInfo/GET_ALL_SUCCESS',
  GET_INFO_ERROR: 'InsuranceInfo/GET_ALL_ERROR',
  RESET: 'InsuranceInfo/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INFO:
      return { ...state, loading: true };
    case actionTypes.GET_INFO_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
      };
    case actionTypes.GET_INFO_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getInsuranceInfos: (filter) => async dispatch => {
    dispatch({ type: actionTypes.GET_INFO });
    try {
      filter = filter ?? {};
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.insurance_info}${filter_str}`);
      dispatch({
        type: actionTypes.GET_INFO_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_INFO_ERROR,
          payload: { error: 'Não foi possível buscar os informativos no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_INFO_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_INFO_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_INFO_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getInsuranceInfo: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.insurance_info}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter o informativo. Por favor, tente novamente' };
    }
  },
  createInsuranceInfo: async (fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => formData.append(fieldName, fields[fieldName]));
      const { data } = await axios.post(`${Endpoints.insurance_info}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar o informativo. Por favor, tente novamente' };
    }
  },
  updateInsuranceInfo: async (insuranceInfoId, fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => {
        if (fieldName === 'report') {
          if (!(typeof fields.report === 'string' || fields.report instanceof String)) {
            // se for string nao deve adicionar pois tratasse do link que veio do backend
            formData.append(fieldName, fields[fieldName]);
          }
        } else if (fields[fieldName]) {
          formData.append(fieldName, fields[fieldName]);
        }
      });
      const { data } = await axios.put(`${Endpoints.insurance_info}/${insuranceInfoId}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar o informativo. Por favor, tente novamente' };
    }
  },
  deleteInsuranceInfo: async (insuranceInfoId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.insurance_info}/${insuranceInfoId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar o informativo. Por favor, tente novamente' };
    }
  },
  resetInsuranceInfos: () => ({ type: actionTypes.RESET })
};
