import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_TERMS: 'terms/GET_ALL',
  GET_TERMS_SUCCESS: 'terms/GET_ALL_SUCCESS',
  GET_TERMS_ERROR: 'terms/GET_ALL_ERROR',
  RESET: 'terms/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TERMS:
      return { ...state, loading: true };
    case actionTypes.GET_TERMS_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
      };
    case actionTypes.GET_TERMS_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getTerms: (filter) => async dispatch => {
    dispatch({ type: actionTypes.GET_TERMS });
    try {
      filter = filter ?? {};
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.terms_of_use}${filter_str}`);
      dispatch({
        type: actionTypes.GET_TERMS_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_TERMS_ERROR,
          payload: { error: 'Não foi possível buscar os termos de uso no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_TERMS_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_TERMS_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_TERMS_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getTerm: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.terms_of_use}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter o termos de uso. Por favor, tente novamente' };
    }
  },
  createTerms: async (fields) => {
    try {
      const { data } = await axios.post(`${Endpoints.terms_of_use}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar o termos de uso. Por favor, tente novamente' };
    }
  },
  updateTerms: async (termId, fields) => {
    try {
      const { data } = await axios.put(`${Endpoints.terms_of_use}/${termId}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar o termos de uso. Por favor, tente novamente' };
    }
  },
  deleteTerms: async (termsId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.terms_of_use}/${termsId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar o termos de uso. Por favor, tente novamente' };
    }
  },
  resetTerms: () => ({ type: actionTypes.RESET })
};