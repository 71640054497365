import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';
import moment from 'moment';

export const actionTypes = {
  GET_CREDIT_CARD: 'credit_card/GET_ALL',
  GET_CREDIT_CARD_SUCCESS: 'credit_card/GET_ALL_SUCCESS',
  GET_CREDIT_CARD_ERROR: 'credit_card/GET_ALL_ERROR',
  RESET: 'credit_card/RESET',
  RESET_FILTER_INFO: 'credit_card_info/RESET_FILTER_INFO',
};

const initialState = {
  loading: false,
  error: null,
  data: {},
  filter: {},
  filter_info: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CREDIT_CARD:
      return { ...state, loading: true };
    case actionTypes.GET_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        filter: action.payload.filter,
        filter_info: action.payload.filter_info,
      };
    case actionTypes.GET_CREDIT_CARD_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...initialState };
    case actionTypes.RESET_FILTER_INFO:
      return { ...state, filter_info: initialState.filter_info };
    default:
      return state;
  }
}

// policy
export const Creators = {
  getCreditCards: (filter, filter_info) => async dispatch => {
    dispatch({ type: actionTypes.GET_CREDIT_CARD });
    try {
      filter = filter ?? {};
      filter_info = filter_info ?? {};
      filter['limit'] = '100';

      const dateFields = [
        'created_at__date__gte',
        'created_at__date__lte',
        'finished_at__date__gte',
        'finished_at__date__lte',
      ];

      dateFields.forEach(field => {
        if (filter[field]) {
          const date = moment(filter[field]);
          if (date.isValid()) {
            filter[field] = date.format('YYYY-MM-DD');
          } else {
            console.warn(`Invalid date format for ${field}`);
          }
        }
      });

      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.credit_card}${filter_str}`);
      if (Object.keys(filter_info).length === 0) {
        const filter_info_data = await axios.get(`${Endpoints.credit_card_info}`);
        if ((filter_info_data.data?.results?.length ?? 0) > 0) {
          filter_info = filter_info_data.data.results[0];
        }
      }
      dispatch({
        type: actionTypes.GET_CREDIT_CARD_SUCCESS,
        payload: {
          data: data,
          filter: { ...filter },
          filter_info: filter_info,
        },
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_CREDIT_CARD_ERROR,
          payload: { error: 'Não foi possível buscar as propostas no momento' },
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_CREDIT_CARD_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_CREDIT_CARD_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_CREDIT_CARD_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetCreditCards: () => ({ type: actionTypes.RESET }),
  getNotes: async (creditCardId) => {
    try {
      const { data } = await axios.get(`${Endpoints.credit_card}/${creditCardId}/notes/?limit=999`);
      return { data };
    } catch (err) {
      return { error: 'Não foi possível buscar as observações no momento' };
    }
  },
  createNote: async (creditCardId, text) => {
    try {
      const { data } = await axios.post(`${Endpoints.credit_card}/${creditCardId}/notes/`, {text});
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a observação. Por favor, tente novamente' };
    }
  },
  deleteNote: async (creditCardId, noteId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.credit_card}/${creditCardId}/notes/${noteId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a observação. Por favor, tente novamente' };
    }
  },
  updateAnalyst: async (creditCardId, analyst) => {
    try {
      const { data } = await axios.patch(`${Endpoints.credit_card}/${creditCardId}`, {analyst});
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a proposta. Por favor, tente novamente' };
    }
  },
  exportResults: async (filter) => {
    try {
      const aFilter = {...filter ?? {}};
      aFilter['limit'] = undefined;
      const response = await axios({
        url: `${Endpoints.credit_card_export}${stringifyFilters(aFilter)}`, 
        method: 'GET',
        responseType: 'blob', 
      });
      
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'cartão consig.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (err) {
      return { error: 'Não foi possível exportar no momento' };
    }
  },
};
