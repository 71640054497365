import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_POLICY: 'policy/GET_ALL',
  GET_POLICY_SUCCESS: 'policy/GET_ALL_SUCCESS',
  GET_POLICY_ERROR: 'policy/GET_ALL_ERROR',
  RESET: 'policy/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_POLICY:
      return { ...state, loading: true };
    case actionTypes.GET_POLICY_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
      };
    case actionTypes.GET_POLICY_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

// policy

export const Creators = {
  getPolicies: (filter) => async dispatch => {
    dispatch({ type: actionTypes.GET_POLICY });
    try {
      filter = filter ?? {};
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.insurances}${filter_str}`);
      dispatch({
        type: actionTypes.GET_POLICY_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_POLICY_ERROR,
          payload: { error: 'Não foi possível buscar as apólices no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_POLICY_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_POLICY_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_POLICY_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getPolicy: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.insurances}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter a apólice. Por favor, tente novamente' };
    }
  },
  createPolicy: async (fields) => {
    try {
      const { data } = await axios.post(`${Endpoints.insurances}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a apólice. Por favor, tente novamente' };
    }
  },
  updatePolicy: async (policyId, fields) => {
    try {
      const { data } = await axios.put(`${Endpoints.insurances}/${policyId}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a apólice. Por favor, tente novamente' };
    }
  },
  deletePolicy: async (policyId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.insurances}/${policyId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a apólice. Por favor, tente novamente' };
    }
  },
  resetPolicy: () => ({ type: actionTypes.RESET })
};
