// @flow
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Modal, Row, Col, Button } from 'react-bootstrap';

// actions
import { changeSidebarType } from '../store/ducks/layout';

// constants
import * as layoutConstants from '../constants/layout';

// utils
import { changeBodyAttribute } from '../utils';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));

const loading = () => <div className=""></div>;

const VerticalLayout = ({ children, searchOptions, setSearchOptions, confirmDeleteModal, setConfirmDeleteModal }) => {
  const dispatch = useDispatch();

  const { layoutColor, leftSideBarTheme, leftSideBarType, layoutWidth } = useSelector(state => ({
    layoutColor: state.layout.layoutColor,
    layoutWidth: state.layout.layoutWidth,
    leftSideBarTheme: state.layout.leftSideBarTheme,
    leftSideBarType: state.layout.leftSideBarType,
  }));

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  /*
   * layout defaults
   */
  useEffect(() => {
    changeBodyAttribute('data-layout', layoutConstants.LAYOUT_VERTICAL);
  }, []);

  useEffect(() => {
    changeBodyAttribute('data-layout-color', layoutColor);
  }, [layoutColor]);

  useEffect(() => {
    changeBodyAttribute('data-layout-mode', layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    changeBodyAttribute('data-leftbar-theme', leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeBodyAttribute('data-leftbar-compact-mode', leftSideBarType);
  }, [leftSideBarType]);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened(prevState => {
      setIsMenuOpened(!prevState);
    });

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable');
      } else {
        document.body.classList.add('sidebar-enable');
      }
    }
  };

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth <= 760) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_MOBILE_CONDENSED));
    }
    if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
    } else if (window.innerWidth > 1028) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
    }
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [dispatch, updateDimensions]);

  const isMobileCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_MOBILE_CONDENSED;
  const isCondensed = leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
  const isLight = leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT;

  return (
    <>
      <div className="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar
            isCondensed={isCondensed}
            isMobileCondensed={isMobileCondensed}
            isLight={isLight}
            hideUserProfile={true}
          />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar
                openLeftMenuCallBack={openMenu}
                hideLogo={true}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}
              />
            </Suspense>
            <Container style={{ paddingTop: searchOptions.showSearch ? '20px' : 0 }} fluid>
              <Outlet />
            </Container>
            <Modal
              show={confirmDeleteModal.show}
              onHide={() => setConfirmDeleteModal({ show: false, deleteMethod: null })}
            >
              <Modal.Body>
                <div style={{ marginTop: '10px', marginBottom: '30px', textAlign: 'center' }}>
                  <i className="mdi mdi-alert-circle-outline" style={{ fontSize: '45px', color: '#c63328' }}></i>
                  <h5>{deleteLoading ? 'Excluindo...' : 'Tem certeza que deseja excluir?'}</h5>
                </div>
                <Row>
                  <Col>
                    <Button
                      variant="secondary"
                      size="md"
                      disabled={deleteLoading}
                      onClick={() => setConfirmDeleteModal({ show: false, deleteMethod: null })}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="danger"
                      size="md"
                      style={{ float: 'right' }}
                      disabled={deleteLoading}
                      onClick={async () => {
                        if (!deleteLoading && confirmDeleteModal.deleteMethod) {
                          setDeleteLoading(true);
                          try {
                            await confirmDeleteModal.deleteMethod();
                          } finally {
                            setDeleteLoading(false);
                            if (confirmDeleteModal.refreshMethod) {
                              confirmDeleteModal.refreshMethod();
                            } else {
                              window.location.reload();
                            }
                          }
                        }
                      }}
                    >
                      Excluir
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
    </>
  );
};
export default VerticalLayout;
