import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_MESSAGES: 'messages/GET_ALL',
  GET_MESSAGES_SUCCESS: 'messages/GET_ALL_SUCCESS',
  GET_MESSAGES_ERROR: 'messages/GET_ALL_ERROR',
  RESET: 'messages/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MESSAGES:
      return { ...state, loading: true };
    case actionTypes.GET_MESSAGES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_MESSAGES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getMessages: () => async dispatch => {
    dispatch({ type: actionTypes.GET_MESSAGES });
    try {
      const { data } = await axios.get(`${Endpoints.messages}`);
      dispatch({
        type: actionTypes.GET_MESSAGES_SUCCESS,
        payload: { data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_MESSAGES_ERROR,
          payload: { error: 'Não foi possível buscar as mensagens no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_MESSAGES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_MESSAGES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_MESSAGES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getMessage: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.messages}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter a mensagem. Por favor, tente novamente' };
    }
  },
  createMessage: async (fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => formData.append(fieldName, fields[fieldName]));
      const { data } = await axios.post(`${Endpoints.messages}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a mensagem. Por favor, tente novamente' };
    }
  },
  sendMailMessage: async (id) => {
    try {
      return await axios.get(`${Endpoints.messages_send_mail}/?id=${id}`);
    } catch (err) {
      return { error: 'Ocorreu um erro ao enviar os emails. Por favor, tente novamente mais tarde' };
    }
  },
  updateMessage: async (messageId, fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => formData.append(fieldName, fields[fieldName]));
      const { data } = await axios.put(`${Endpoints.messages}/${messageId}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a mensagem. Por favor, tente novamente' };
    }
  },
  deleteMessage: async (messageId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.messages}/${messageId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a mensagem. Por favor, tente novamente' };
    }
  },
  resetMessages: () => ({ type: actionTypes.RESET })
};