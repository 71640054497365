import { Navigate, useLocation } from 'react-router-dom';
import store from '../store';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: RouteComponent,
  searchOptions,
  setSearchOptions,
  confirmDeleteModal,
  setConfirmDeleteModal,
  ...rest
}) => {
  let location = useLocation();
  const user = store.getState().user.data;
  const userId = user?.id ?? 0;

  if (!userId) {
    return <Navigate to={'/account/login'} state={{ from: location }} replace />;
  }

  return (
    <RouteComponent
      searchOptions={searchOptions}
      setSearchOptions={setSearchOptions}
      confirmDeleteModal={confirmDeleteModal}
      setConfirmDeleteModal={setConfirmDeleteModal}
    />
  );
};

export default PrivateRoute;
