import * as layoutConstants from '../../constants/layout';

export const LayoutActionTypes = {
  CHANGE_LAYOUT: '@@layout/CHANGE_LAYOUT',
  CHANGE_LAYOUT_COLOR: '@@layout/CHANGE_LAYOUT_COLOR',
  CHANGE_LAYOUT_WIDTH: '@@layout/CHANGE_LAYOUT_WIDTH',
  CHANGE_SIDEBAR_THEME: '@@layout/CHANGE_SIDEBAR_THEME',
  CHANGE_SIDEBAR_TYPE: '@@layout/CHANGE_SIDEBAR_TYPE',

  TOGGLE_RIGHT_SIDEBAR: '@@layout/TOGGLE_RIGHT_SIDEBAR',
  SHOW_RIGHT_SIDEBAR: '@@layout/SHOW_RIGHT_SIDEBAR',
  HIDE_RIGHT_SIDEBAR: '@@layout/HIDE_RIGHT_SIDEBAR',
};

const INIT_STATE = {
  layoutColor: layoutConstants.LAYOUT_COLOR_LIGHT,
  layoutType: layoutConstants.LAYOUT_VERTICAL,
  layoutWidth: layoutConstants.LAYOUT_WIDTH_FLUID,
  leftSideBarTheme: layoutConstants.LEFT_SIDEBAR_THEME_DARK,
  leftSideBarType: layoutConstants.LEFT_SIDEBAR_TYPE_FIXED,
  showRightSidebar: false,
};

type LayoutAction = { type: string, payload?: string | null };

type State = {
  layoutType: string,
  layoutColor: string,
  layoutWidth: string,
  leftSideBarTheme: string,
  leftSideBarType: string,
  showRightSidebar?: boolean,
};

export default function Layout(state: State = INIT_STATE, action: LayoutAction) {
  switch (action.type) {
      case LayoutActionTypes.CHANGE_LAYOUT:
          return {
              ...state,
              layoutType: action.payload,
          };
      case LayoutActionTypes.CHANGE_LAYOUT_COLOR:
          return {
              ...state,
              layoutColor: action.payload,
          };
      case LayoutActionTypes.CHANGE_LAYOUT_WIDTH:
          return {
              ...state,
              layoutWidth: action.payload,
          };
      case LayoutActionTypes.CHANGE_SIDEBAR_THEME:
          return {
              ...state,
              leftSideBarTheme: action.payload,
          };
      case LayoutActionTypes.CHANGE_SIDEBAR_TYPE:
          return {
              ...state,
              leftSideBarType: action.payload,
          };
      case LayoutActionTypes.SHOW_RIGHT_SIDEBAR:
          return {
              ...state,
              showRightSidebar: true,
          };
      case LayoutActionTypes.HIDE_RIGHT_SIDEBAR:
          return {
              ...state,
              showRightSidebar: false,
          };
      default:
          return state;
  }
};

export const changeLayout = (layout: string): LayoutAction => ({
    type: LayoutActionTypes.CHANGE_LAYOUT,
    payload: layout,
});

export const changeLayoutColor = (color: string): LayoutAction => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
    payload: color,
});

export const changeLayoutWidth = (width: string): LayoutAction => ({
    type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
    payload: width,
});

export const changeSidebarTheme = (theme: string): LayoutAction => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
    payload: theme,
});

export const changeSidebarType = (sidebarType: string): LayoutAction => ({
    type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
    payload: sidebarType,
});

export const showRightSidebar = (): LayoutAction => ({
    type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
    payload: null,
});

export const hideRightSidebar = (): LayoutAction => ({
    type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
    payload: null,
});
