import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_FILES: 'files/GET_ALL',
  GET_FILES_SUCCESS: 'files/GET_ALL_SUCCESS',
  GET_FILES_ERROR: 'files/GET_ALL_ERROR',
  RESET: 'files/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_FILES:
      return { ...state, loading: true };
    case actionTypes.GET_FILES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_FILES_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getFiles: () => async dispatch => {
    dispatch({ type: actionTypes.GET_FILES });
    try {
      const { data } = await axios.get(`${Endpoints.products_files}?limit=9999`);
      dispatch({
        type: actionTypes.GET_FILES_SUCCESS,
        payload: { data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_FILES_ERROR,
          payload: { error: 'Não foi possível buscar as manuais no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_FILES_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_FILES_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FILES_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getFile: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.files}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter o manual. Por favor, tente novamente' };
    }
  },
  createFile: async (fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => formData.append(fieldName, fields[fieldName]));
      const { data } = await axios.post(`${Endpoints.files}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar o manual. Por favor, tente novamente' };
    }
  },
  updateFile: async (fileId, fields) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      const formData = new FormData();
      Object.keys(fields).forEach((fieldName) => {
        if (fieldName === 'file') {
          if (!(typeof fields.file === 'string' || fields.file instanceof String)) {
            // se for string nao deve adicionar pois tratasse do link que veio do backend
            formData.append(fieldName, fields[fieldName]);
          }
        } else {
          formData.append(fieldName, fields[fieldName]);
        }
      });
      const { data } = await axios.put(`${Endpoints.files}/${fileId}`, formData, config);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar o manual. Por favor, tente novamente' };
    }
  },
  deleteFile: async (fileId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.files}/${fileId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar o manual. Por favor, tente novamente' };
    }
  },
  resetFiles: () => ({ type: actionTypes.RESET })
};