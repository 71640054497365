import Endpoints from '../endpoints';
import axios from '../api';
import ErrorMessages from '../../shared/ErrorMessages';
import { stringifyFilters } from '../../shared/utility';
import { Creators as UserCreators } from './user';

export const actionTypes = {
  GET_COMPANY: 'company/GET_ALL',
  GET_COMPANY_SUCCESS: 'company/GET_ALL_SUCCESS',
  GET_COMPANY_ERROR: 'company/GET_ALL_ERROR',
  RESET: 'company/RESET',
}

const initialState = {
  loading: false,
  error: null,
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_COMPANY:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        data: action.payload.data, 
      };
    case actionTypes.GET_COMPANY_ERROR:
      return { ...state, loading: false, error: action.payload.error };
    case actionTypes.RESET:
      return { ...state, initialState };
    default:
      return state;
  }
}

export const Creators = {
  getCompanies: (filter) => async dispatch => {
    dispatch({ type: actionTypes.GET_COMPANY });
    try {
      filter = filter ?? {};
      const filter_str = stringifyFilters(filter);
      const { data } = await axios.get(`${Endpoints.companies}${filter_str}`);
      dispatch({
        type: actionTypes.GET_COMPANY_SUCCESS,
        payload: { data: data }
      });
    } catch (err) {
      const { response } = err;
      if (response?.status === 404) {
        dispatch({
          type: actionTypes.GET_COMPANY_ERROR,
          payload: { error: 'Não foi possível buscar as empresas no momento' }
        });
      } else if (response?.status === 403 && response?.data?.detail) {
        dispatch({
          type: actionTypes.GET_COMPANY_ERROR,
          payload: { error: response?.data?.detail },
        });
      } else if (response?.status === 401) {
        UserCreators.logoutAndRedirect();
        dispatch({
          type: actionTypes.GET_COMPANY_ERROR,
          payload: { error: ErrorMessages.defaultCredentialsError },
        });
      } else {
        dispatch({
          type: actionTypes.GET_COMPANY_ERROR,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  getCompany: async (id) => {
    try {
      const { data } = await axios.get(`${Endpoints.companies}/${id}`);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar obter a empresa. Por favor, tente novamente' };
    }
  },
  createCompany: async (fields) => {
    try {
      const { data } = await axios.post(`${Endpoints.companies}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar criar a empresa. Por favor, tente novamente' };
    }
  },
  updateCompany: async (companyId, fields) => {
    try {
      const { data } = await axios.put(`${Endpoints.companies}/${companyId}`, fields);
      return { data };
    } catch (err) {
      if (err?.response?.data) {
        return { error: err.response.data };
      }
      return { error: 'Ocorreu um erro ao tentar atualizar a empresa. Por favor, tente novamente' };
    }
  },
  deleteCompany: async (companyId) => {
    try {
      const { data } = await axios.delete(`${Endpoints.companies}/${companyId}`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar deletar a empresa. Por favor, tente novamente' };
    }
  },
  restoreCompany: async (companyId) => {
    try {
      const { data } = await axios.post(`${Endpoints.companies}/${companyId}/restore`);
      return { data };
    } catch (err) {
      return { error: 'Ocorreu um erro ao tentar restaurar a empresa. Por favor, tente novamente' };
    }
  },
  resetCompany: () => ({ type: actionTypes.RESET })
};